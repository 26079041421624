/* eslint-disable react/prop-types */
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'src/lib/configs/breakpoints';

// TODO: Tests! These were added outside of ticket
// scope and I am out of time to write tests. I will
// add tests during Ads work. -DC

// To use, simply wrap your content or components in one
// of the Breakpoint components provided here. By design,
// the media queries for these components match their
// Boostrap media-query mixin counterparts.

// Breakpoints Between
const BreakpointBetween = ({ children, minBreakpoint, maxBreakpoint }) => {
  const isBreakpoint = useMediaQuery({
    maxWidth: breakpoints[maxBreakpoint].max,
    minWidth: breakpoints[minBreakpoint].min,
  });
  return isBreakpoint ? children : null;
};

// Breakpoints Only
const BreakpointOnlyXl = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.xl.min });
  return isBreakpoint ? children : null;
};

const BreakpointOnlyLg = ({ children }) => {
  const isBreakpoint = useMediaQuery({
    maxWidth: breakpoints.lg.max,
    minWidth: breakpoints.lg.min,
  });
  return isBreakpoint ? children : null;
};

const BreakpointOnlyMd = ({ children }) => {
  const isBreakpoint = useMediaQuery({
    maxWidth: breakpoints.md.max,
    minWidth: breakpoints.md.min,
  });
  return isBreakpoint ? children : null;
};

const BreakpointOnlySm = ({ children }) => {
  const isBreakpoint = useMediaQuery({
    maxWidth: breakpoints.sm.max,
    minWidth: breakpoints.sm.min,
  });
  return isBreakpoint ? children : null;
};

const BreakpointOnlyXs = ({ children }) => {
  const isBreakpoint = useMediaQuery({ maxWidth: breakpoints.xs.max });
  return isBreakpoint ? children : null;
};

// Breakpoints Up
const BreakpointUpXl = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.xl.min });
  return isBreakpoint ? children : null;
};

const BreakpointUpLg = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.lg.min });
  return isBreakpoint ? children : null;
};

const BreakpointUpMd = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.md.min });
  return isBreakpoint ? children : null;
};

const BreakpointUpSm = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.sm.min });
  return isBreakpoint ? children : null;
};

const BreakpointUpXs = ({ children }) => {
  const isBreakpoint = useMediaQuery({ minWidth: breakpoints.xs.min });
  return isBreakpoint ? children : null;
};

export {
  BreakpointBetween,
  BreakpointOnlyXl,
  BreakpointOnlyLg,
  BreakpointOnlyMd,
  BreakpointOnlySm,
  BreakpointOnlyXs,
  BreakpointUpXl,
  BreakpointUpLg,
  BreakpointUpMd,
  BreakpointUpSm,
  BreakpointUpXs,
};
